import React from "react"
import Image from "gatsby-image";
import "../../styles/offer.scss"
import OfferHeader from "../../components/OfferHeader";
import OfferItem from "../../components/OfferItem"
import Realizations from "../../components/Realizations"
import { graphql } from "gatsby"

import SEO from "../../components/seo"

const dataLeatherMaking = {
    header: "Tworzymy wyroby skórzane w oparciu o tradycję i jakość polskiego rzemiosła",
    text: "Zależy Ci na produktach zachwycających wykonaniem i przystępnych cenowo? Klienci często powierzają nam wyjątkowe, sentymentalne przedmioty wiedząc, że zadbamy o nie z należytą starannością. Zachęcamy do zapoznania się z naszą ofertą.",
    link: "prices",
    headerItem1: "Wytwarzamy, konserwujemy oraz naprawiamy",
    descItem1: "Zajmujemy się wyrabianiem oraz naprawianiem różnych skórzanych materiałów, takich jak: torebki, plecaki, walizki, pokrowce, paski, portfele i wiele innych.",
    headerItem2: "Gwarantujemy szeroki zakres prac kaletniczych",
    descItem2: "Oferujemy naprawę lub wymianę zamków, wszelkiego rodzaju klamerek, napinaczy, karabińczyków czy kółek. Jeśli potrzebujesz wykonać przeróbkę lub zrobić nową dziurkę w pasku lub w innej galanterii skórzanej to zapraszamy do naszego zakładu.",
    realizationHeader: "Nasze przykładowe realizacje",
    relazationDesc: "Realizujemy różnorodne prace krawieckie. Zapewniamy pełen profesjonalizm, nasze wieloletnie doświadczenie w branży pozwala nam wykonywać usługi solidnie, szybko i za rozsądną cenę."

}


const description = "Usługi kaletnicze. Szycie naprawa galanterii skórzanej. Paski, torebki, plecaki, walizki, pokrowce, portfele. Dorabianie dziurek. Wymiana zamków. Konin"
const keywords = ["usługi kaletnicze", "naprawa plandek", "naprawa namiotów"]

const LetherMakingOfferPage = ({ data }) => (
    <>
        <SEO
            title="Oferta | Kaletnik"
            description={description}
            keywords={keywords}
        />
        <section className="offerPage container">
            <OfferHeader
                headerOffer={dataLeatherMaking.header}
                textOffer={dataLeatherMaking.text}
            />

            <div className="imgContainer leather">
                <div className="vertical">
                    <Image fluid={data.gloves.childImageSharp.fluid} />
                    <Image fluid={data.bag.childImageSharp.fluid} />

                </div>
                <div className="bag">
                    <Image fluid={data.bag2.childImageSharp.fluid} />

                </div>
            </div>
            <Realizations
                header={dataLeatherMaking.realizationHeader}
                desc={dataLeatherMaking.relazationDesc}
                images={data.containers}
                linkPath={"galeria/pozostale"}
            />

            <div className="offerItemsContainer">
                <OfferItem
                    headerItem={dataLeatherMaking.headerItem1}
                    descItem={dataLeatherMaking.descItem1}
                    img={data.bag4.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataLeatherMaking.headerItem2}
                    descItem={dataLeatherMaking.descItem2}
                    img={data.bag3.childImageSharp.fluid}
                    leftImg
                />
            </div>

        </section>
    </>
)

export const query = graphql`
  {
    bag: file(name: {eq: "leather_bag"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    bag2: file(name: {eq: "leather_bag2"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    gloves: file(name: {eq: "leather_gloves"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    bag3: file(name: {eq: "leather_bag3"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    bag4: file(name: {eq: "leather_bagForest"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "leatherMaking/realizations"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },

  }
`

export default LetherMakingOfferPage
